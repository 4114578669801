import React, { useEffect, useState } from "react";
import { corporateDataCapture as v } from "./validate";
import { Field, getFormValues } from "redux-form";
import { connect, useSelector } from "react-redux";
import { isCorporate, isSuperAgent, token } from "../../modules/auth";
import { Select } from "../../ui";
import {
  corporateAccountBookingDesksUnload,
  corporateDepartmentsUnload,
  fetchCompanyBranches,
  fetchCorporateAccountUsers,
  fetchCorporateBookingDesks,
  fetchCorporateDepartments,
  unloadCompanyBranches,
  unloadCorporateAccountUsers,
} from "../../redux/actions";
import { canCreateHighlyConfidentialRfp, corporateAccountObject } from "../../modules/permissions";
import { Toggle } from "../../Components/Form";
import { LiveSearchSimple } from "../../Components/Form/LiveSearch/LiveSearchSimple";

const mapStateToProps = state => {
  return ({
    token: token(state),
    isCorporate: isCorporate(state),
    isSuperAgent: isSuperAgent(state),
    corporateAccount: corporateAccountObject(state.auth),
    canCreateHighlyConfidentialRfp: canCreateHighlyConfidentialRfp(state.auth),
    ...state.corporateBookingDesks,
    ...state.corporateAccountUsers,
    ...state.corporateDepartments,
    ...state.companyBranches,
  });
};

const mapDispatchToProps = {
  fetchCorporateBookingDesks,
  corporateAccountBookingDesksUnload,
  fetchCorporateDepartments,
  corporateDepartmentsUnload,
  fetchCorporateAccountUsers,
  unloadCorporateAccountUsers,
  fetchCompanyBranches,
  unloadCompanyBranches,
};

const CorporateSpecificEnquiryData = (props) => {
  const [bookingDesk, setBookingDesk] = useState(null);
  const [department, setDepartment] = useState(null);
  const [meetingHost, setMeetingHost] = useState(null);
  const values = useSelector(getFormValues(props.form));

  useEffect(() => {
    if (props.corporateAccount && props.corporateAccount.id) {
      props.fetchCorporateBookingDesks(props.corporateAccount.id, props.token);
      props.fetchCorporateDepartments(props.corporateAccount.id, props.token);
      props.fetchCorporateAccountUsers(props.corporateAccount.id, props.token);
    }
    return () => {
      props.corporateAccountBookingDesksUnload();
      props.corporateDepartmentsUnload();
      props.unloadCorporateAccountUsers();
    };
  }, []);

  useEffect(() => {
    if (props?.company?.corporate_account_id) {
      props.fetchCorporateBookingDesks(props.company.corporate_account_id, props.token);
      props.fetchCorporateDepartments(props.company.corporate_account_id, props.token);
      props.fetchCorporateAccountUsers(props.company.corporate_account_id, props.token);
    }
    return () => {
      props.unloadCompanyBranches();
      props.corporateAccountBookingDesksUnload();
      props.corporateDepartmentsUnload();
      props.unloadCorporateAccountUsers();
    };
  }, [props.company]);

  useEffect(() => {
    handleMeetingHostSelection(props.meetingHostId);
  }, [props.meetingHostId]);

  useEffect(() => {
    handleMeetingHostSelection(props.meetingHostId);
  }, [props.corporateAccountUsers]);

  useEffect(() => {
    if (values.meeting_host_id && props.corporateAccountUsers && props.corporateAccountUsers.users) {
      props.corporateAccountUsers.users.map(user => {
        if (meetingHost && meetingHost.id === user.id && user.department != null) {
          if (typeof values.department_id === "undefined" || values.department_id === null) {
            props.change(props.form, "department_id", user.department);
          }
          setDepartment(user.department);
        }
      });
      // let meetingHost = props.corporateAccountUsers.users.filter((user) => {
      //   return user.user_id == values.meeting_host_id;
      // })[0];
    }
    if (meetingHost) {
      props.change(props.form, "meeting_host_id", meetingHost.id);
    }
  }, [meetingHost]);

  const getBookingDesksOptions = () => {
    let options = [];
    if (props.company.default_booking_desk_id && props.company.default_booking_desk) {
      options.push({
        text: props.company.default_booking_desk.name,
        value: props.company.default_booking_desk.id,
      });
    }
    if (props.corporateBookingDesks && props.corporateBookingDesks.length > 0) {
      props.corporateBookingDesks.map(corporateBookingDesk => {
        options.push({
          text: corporateBookingDesk.name,
          value: corporateBookingDesk.id,
        });
      });
    }
    return options;
  };

  const getDepartmentsOptions = () => {
    let options = [];
    if (props.corporateDepartments && props.corporateDepartments.length > 0) {
      const departmentMap = new Map();
      props.corporateDepartments.forEach(department => {
        const { id, name, parent } = department;
        departmentMap.set(id, { text: name, value: id, parentId: parent ? parent.id : null });
      });

      props.corporateDepartments.forEach(department => {
        const { id, name, parent } = department;
        if (parent) {
          options.push({ text: name, value: id });
        }
      });

      props.corporateDepartments.forEach(department => {
        const { id, name, parent } = department;
        if (!parent) {
          options.push({ text: name, value: id });
        }
      });
    }

    return options;
  };

  const getMeetingHostsOptions = () => {
    let options = [];
    if (props.corporateAccountUsers && props.corporateAccountUsers.users && props.corporateAccountUsers.users.length > 0) {
      props.corporateAccountUsers.users.map(user => {
        options.push({
          text: user.first_name + " " + user.surname + " (" + user.email + ")",
          value: user.id,
        });
      });
    }
    return options;
  };

  const handleMeetingHostSelection = (meetingHostId) => {
    if (props.corporateAccountUsers && props.corporateAccountUsers.users) {
      props.corporateAccountUsers.users.map(user => {
        if (meetingHostId === user.id) {
          setMeetingHost(user);
        }
      });
    }
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col col-w-1/4">
          {props.isSuperAgent && getBookingDesksOptions().length > 0 && (
            <Field
              name="booking_desk_id"
              label="Booking Desk"
              component={Select}
              options={getBookingDesksOptions()}
              onChange={e => {
                if (e.target.value == "") {
                  props.change(props.form, "agency_booking_desk_id", null);
                } else if (e.target.value == props.company.default_booking_desk_id) {
                  props.change(props.form, "agency_booking_desk_id", e.target.value);
                } else if (e.target.value != props.company.default_booking_desk_id) {
                  props.change(props.form, "agency_booking_desk_id", null);
                }
                setBookingDesk(e.target.value);
              }}
              // validate={v.booking_desk_id}
              wrapperClassName="inline mb-8 xl:w-1/2"
            />
          )}
        </div>
      </div>
      {props.canCreateHighlyConfidentialRfp ?
        <div className="row">
          <div className="col col-w-1/4">
            <Field
              name="is_highly_confidential"
              label="Is Confidential?"
              component={Toggle}
            />
          </div>
        </div>
        : null}
      <div className="row">
        <div className="col col-w-1/4">
          <LiveSearchSimple
            auth
            label="Meeting Host"
            getText={result => result.first_name + " " + result.surname + ": " + result.email}
            url={`${window.API}/users?corporateUser=1&corporateId=${props?.company?.corporate_account_id}`}
            onChange={setMeetingHost}
            query="companyUser"
            onClear={() => setMeetingHost(null)}
            placeholder={meetingHost && meetingHost.first_name + " " + meetingHost.surname + ": " + meetingHost.email}
            validate={getMeetingHostsOptions().length > 0 ? v.meeting_host_id : null}
            wrapperClassName="inline mb-8 xl:w-1/2"
          />
          {/*<Field*/}
          {/*  name="meeting_host_id"*/}
          {/*  label="Meeting Host"*/}
          {/*  component={Select}*/}
          {/*  options={getMeetingHostsOptions()}*/}
          {/*  validate={getMeetingHostsOptions().length > 0 ? v.meeting_host_id : null}*/}
          {/*  onChange={e => {*/}
          {/*    handleMeetingHostSelection(e.target.value);*/}
          {/*    if (values.meeting_host_id && props.corporateAccountUsers && props.corporateAccountUsers.users) {*/}
          {/*      let meetingHost = props.corporateAccountUsers.users.filter((user) => {*/}
          {/*        return user.user_id == values.meeting_host_id;*/}
          {/*      })[0];*/}
          {/*      props.change(props.form, "department_id", meetingHost.department);*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  wrapperClassName="inline mb-8 xl:w-1/2"*/}
          {/*/>*/}
        </div>
        <div className="col col-w-1/4">
          {getDepartmentsOptions().length > 0 && (
            <Field
              name="department_id"
              label="Department"
              validate={v.department_id}
              component={Select}
              options={getDepartmentsOptions()}
              onChange={e => setDepartment(e.target.value)}
              wrapperClassName="inline mb-8 xl:w-1/2"
            />
          )}
        </div>
        {props.corporateAccount && props.corporateAccount.is_pharmaceutical && props.corporateAccount.show_internal_external_on_brief ?
          <Field
            name="is_external"
            label="Is external?"
            component={Toggle}
          />
          : null}
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateSpecificEnquiryData);
