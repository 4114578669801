import axios from "axios";

const getVersions = async () => {
  try {
    const response = await axios.get(`${window.API}/edge-versions`);
    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

export { getVersions };
