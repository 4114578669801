import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Textarea, Input, Radio } from "../../../Components/Form";
import { ButtonPrimary } from "../../../Components/Button";
import parseErrors from "modules/parse-errors";
import { averageTemperatureFields } from "../../../modules/destinations";
import Loading from "../../../Components/Loading";

const nullable = _ => (_ === "" ? null : _);
const formatFloat = _ => {
  const value = parseFloat(_);
  return isNaN(value) ? "" : String(value);
};

class Edit extends Component {
  static propTypes = {
    destination: PropTypes.object,
    api: PropTypes.object,
  };

  componentDidMount() {
    const { initialize, destination } = this.props;
    initialize(destination);
  }

  handleSubmit = values => {
    delete values.city
    const {
      api: { updateDestination: apiUpdateDestination, handleError },
      updateDestination,
    } = this.props;

    return apiUpdateDestination({ destination: values })
      .then(response => updateDestination(response.data))
      .catch(
        handleError(err => {
          throw new SubmissionError({
            ...parseErrors(err),
            _error: "Sorry, there was an error submitting your data",
          });
        })
      );
  };

  render() {
    const {
      destination,
      handleSubmit,
      submitting,
      submitSucceeded,
      submitFailed,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <h1 className="pt-0">
          Edit - {destination.city} - {destination.country.name}
        </h1>
        <h2 className="my-2">General information</h2>

        <Field
          name="profile"
          label="Profile"
          parse={nullable}
          component={Textarea}
          rows={8}
        />

        <div className="flex flex-wrap -mx-4">
          {/*<div className="w-full lg:w-1/2 xl:w-1/3 px-4">*/}
          {/*  <Field*/}
          {/*    name="vfm"*/}
          {/*    label="Value for Money"*/}
          {/*    parse={nullable}*/}
          {/*    component={Textarea}*/}
          {/*    rows={6}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="w-full lg:w-1/2 px-4">
            <Field
              name="health_advice"
              label="Health Advice"
              parse={nullable}
              component={Textarea}
              rows={6}
            />
          </div>

          <div className="w-full xl:w-1/2 px-4">
            <Field
              name="notes"
              label="Notes"
              parse={nullable}
              component={Textarea}
              rows={6}
            />
          </div>
        </div>

        <Field name="is_resort" label="Is Resort" component={Radio} />

        <h2 className="my-2">Location</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-1/4 px-4">
            <Field
              name="lat"
              label="Latitude"
              type="number"
              max={90}
              min={-90}
              step="any"
              format={formatFloat}
              parse={nullable}
              component={Input}
            />
          </div>
          <div className="lg:w-1/4 px-4">
            <Field
              name="long"
              label="Longitude"
              type="number"
              max={180}
              min={-180}
              step="any"
              format={formatFloat}
              parse={nullable}
              component={Input}
            />
          </div>
          <div className="lg:w-1/4 px-4">
            <Field
              name="time_difference"
              label="Time Difference"
              type="number"
              min={-13}
              max={14}
              parse={nullable}
              component={Input}
            />
          </div>
        </div>
        <h2 className="my-2">Travel</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-1/4 px-4">
            <Field
              name="flight_time"
              label="Flight Time"
              parse={nullable}
              component={Input}
            />
          </div>
          <div className="lg:w-1/4 px-4">
            <Field
              name="airlines"
              label="Airlines"
              parse={nullable}
              component={Input}
            />
          </div>
        </div>

        <h2 className="my-2">Contact</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-1/2 px-4">
            <Field
              name="contact"
              label="Name"
              parse={nullable}
              component={Input}
            />
          </div>
          <div className="lg:w-1/2 px-4">
            <Field
              name="contact_email"
              label="Email"
              parse={nullable}
              component={Input}
            />
          </div>
        </div>

        <h2 className="my-2">Convention Bureau</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-1/2 px-4">
            <Field
              name="cb_name"
              label="Name"
              parse={nullable}
              component={Input}
            />
          </div>

          <div className="lg:w-1/2 px-4">
            <Field
              name="phone"
              label="Phone"
              parse={nullable}
              component={Input}
            />
          </div>
          <div className="lg:w-1/2 px-4">
            <Field name="fax" label="Fax" parse={nullable} component={Input} />
          </div>
          <div className="lg:w-1/2 px-4">
            <Field
              name="email"
              label="Email"
              parse={nullable}
              component={Input}
            />
          </div>
          <div className="lg:w-1/2 px-4">
            <Field
              name="website"
              label="Website"
              parse={nullable}
              component={Input}
            />
          </div>
        </div>

        <h2 className="my-2">Average Temperature (°C)</h2>
        <div className="flex flex-wrap -mx-4">
          {averageTemperatureFields.map(field => (
            <div key={field} className="lg:w-1/6 px-4">
              <Field
                name={field}
                format={formatFloat}
                type="number"
                label={field.slice(3)}
                step={0.1}
                parse={nullable}
                component={Input}
              />
            </div>
          ))}
        </div>

        <ButtonPrimary submit>
          {submitting ? <Loading inline /> : "Save"}
        </ButtonPrimary>
        {submitSucceeded ? <p className="my-2">Saved</p> : null}
        {submitFailed ? (
          <p className="my-2 text-warning font-normal">
            Save Failed, please check the form for errors
          </p>
        ) : null}
      </form>
    );
  }
}

export default reduxForm({
  form: "edit-destination",
})(Edit);
