import React, {Fragment} from "react";
import {bool, number, shape, string} from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {isAgencyApproved, isAgent, isInternal, isLoggedIn, isSuperAgent, isVenueAdmin} from "modules/auth";
import HeaderWrap from "./HeaderWrap";
import {homepageItems, wpRedirects} from "modules/menu-items";
import {FaBars, FaCaretDown} from "react-icons/fa";
import MenuDrawer from "./MenuDrawer";
import {getWidth} from "redux-window";
import {generalInteraction} from "../../modules/analytics";
import * as ROUTE from "../../Router/public_routes";

const HashedMenuItem = ({item, linkClass, subClass}) => (
    <li className={`${linkClass}`}>
        <Link
            to={"/#agency-offers"}
            className={`text-black hover:text-green transition ${!!item.submenu &&
            " with-submenu"}`}
        >
            {"Agency News"}
            {!!item.submenu && <FaCaretDown className="ml-1 text-black"/>}
        </Link>
        {!!item.submenu && (
            <div className="submenu z-50 border border-light-blue-grey shadow w-1/5">
                {item.submenu.map(submenuItem => (
                    <Link
                        to={submenuItem.path}
                        className={`${subClass}`}
                        key={submenuItem.text}
                    >
                        {submenuItem.text}
                    </Link>
                ))}
            </div>
        )}
    </li>
);

const wpRedirectFrom = Object.keys(wpRedirects);

const MenuItem = ({item, linkClass, subClass}) => {


    return (
        <li className={`${linkClass}`}>
            {wpRedirectFrom.includes(item.path) ? (
                <a
                    href={wpRedirects[item.path]}
                    className={`text-black hover:text-green transition ${!!item.submenu &&
                    " with-submenu"}`}
                >
                    {item.text}
                    {!!item.submenu && <FaCaretDown className="ml-1 text-black"/>}
                </a>
            ) : (
                <Link
                    to={item.path}
                    className={`text-black hover:text-green transition ${!!item.submenu &&
                    " with-submenu"}`}
                >
                    {item.text}
                    {!!item.submenu && <FaCaretDown className="ml-1 text-black"/>}
                </Link>
            )}

            {!!item.submenu && (
                <div className="submenu z-50 border border-light-blue-grey shadow w-1/5">
                    {item.submenu.map(submenuItem => (
                        <Link
                            to={submenuItem.path}
                            className={`${subClass}`}
                            key={submenuItem.text}
                        >
                            {submenuItem.text}
                        </Link>
                    ))}
                </div>
            )}
        </li>
    );
}

const Header = ({
                    brand,
                    user: param_user,
                    loggedIn,
                    isInternal,
                    relative,
                    agencyOffersRef,
                    generalInteraction,
                    isAgencyApproved,
                    isSuperAgent,
                }) => {
    const hasBrand = !!brand.id;
    const listAClass =
        "py-2 block font-bold uppercase xl:border-0 xl:py-0";
    const linkClass = `${listAClass} px-2 text-black hover:text-green transition menu-item font-small xxl:text-sm `;
    const submenuClass = ` block px-1 xxl:px-3 text-black hover:text-green transition menu-item py-4 border-b border-light-blue-grey`;
//  const { pathname } = useLocation();
    const mobile = useSelector(state => getWidth(state) < 992);
    const isAgency = useSelector(state => isAgent(state));

    return (
        <HeaderWrap>
            {({menuOpen, toggleMenu}) => (
                <>
                    <MenuDrawer open={menuOpen} toggle={toggleMenu}/>
                    <div
                        className="sticky pin-t z-10 bg-white shadow-sm" style={{boxShadow: "1px 0 2px rgb(0 0 0 / 20%)"}}
                    >
                        <header
                            className={
                                relative ? "container bg-white relative z-10" : "container"
                            }
                        >
                            <div className="container xl:flex xl:justify-between relative">
                                <ul
                                    className={`list-reset hidden xl:flex xl:absolute xl:pin-t xl:pin-r xl:justify-end ${relative &&
                                    "container"}`}
                                >
                                    {isSuperAgent ? (
                                        <li className="bg-light-grey py-2 px-2">
                                            <Link to={ROUTE.FRONTEND.ENQUIRY.NEW} className={listAClass}>
                                                <i className="fas fa-folder-plus pr-2"/>
                                                NEW ENQUIRY
                                            </Link>
                                        </li>
                                    ) : null}
                                    <li className="bg-light-grey py-2 px-3">
                                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                        <div onClick={() => {
                                            generalInteraction({type: "general_header_edge_phone_click"});
                                        }}>
                                            <a className={listAClass} href="tel:441780484051">
                                                <i className="fas fa-phone mr-1"></i> +44(0)1780 484051
                                            </a>
                                        </div>
                                    </li>
                                    {loggedIn ? (
                                        <Fragment>
                                            <li className="bg-light-grey py-2 px-3">
                                                <Link to="/logout" className={listAClass}>
                                                    <i className="fal fa-sign-out-alt mr-1"/>
                                                    &nbsp;Log out
                                                </Link>
                                            </li>
                                            <li className="bg-light-grey py-2 px-3">
                                                <Link to="/admin" className="py-2 block font-bold uppercase xl:border-0 xl:py-0"
                                                      style={{color: "orange"}}>
                                                    <i className="fal fa-user mr-1"/>
                                                    &nbsp;Account
                                                    {param_user && param_user.notifications && (
                                                        <span>
                              &nbsp;
                                                            <span className="bg-primary rounded-full text-center text-white w-5 h-5 inline-block">
                                {param_user.notifications}
                              </span>
                            </span>
                                                    )}
                                                </Link>
                                            </li>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <li className="bg-light-grey py-2 px-3">
                                                <Link to="/login" className={linkClass}>
                                                    Sign in
                                                </Link>
                                            </li>
                                            <li className="bg-light-grey py-2 px-3">
                                                <Link to="/register" className={linkClass}>
                                                    Register
                                                </Link>
                                            </li>
                                        </Fragment>
                                    )}
                                </ul>
                                <div
                                    className="absolute lg:mt-5 xl:hidden pointer"
                                    onClick={toggleMenu}
                                    style={{
                                        right: 10,
                                        top: 20,
                                    }}
                                >
                                    <FaBars className="fa-2x"/>
                                </div>
                                <Link to="/" className={"z-10"}>
                                    {hasBrand ? (
                                        <Fragment>
                                            <div
                                                style={{
                                                    height: mobile ? "42px" : "73px",
                                                    width: mobile ? "85px" : "170px",
                                                }}
                                            >
                                                <img
                                                    src={brand.branding_image_url}
                                                    alt={`${brand.company_name} logo`}
                                                    style={{
                                                        maxHeight: mobile ? "42px" : "73px",
                                                    }}
                                                />
                                            </div>
                                            <p className="mb-2 text-xs">
                                                <span className="text-black">Powered by</span> EDGE
                                                Venues
                                            </p>
                                        </Fragment>
                                    ) : (
                                        <img
                                            src="/img/logo-black.svg"
                                            alt="EDGE Venues logo"
                                            width={mobile ? "110px" : "170px"}
                                            height={mobile ? "auto" : "73px"}
                                            className="pt-3"
                                        />
                                    )}
                                </Link>
                                {/*<Link to="/upgrade-venue">*/}
                                {/*    <img*/}
                                {/*        src="/img/offer-badge-2021.svg"*/}
                                {/*        alt="Upgrade your venue account"*/}
                                {/*        className="pt-3 inline-block"*/}
                                {/*        title="2021 Kickstart Offer: click to upgrade your venue account for only £365..."*/}
                                {/*        style={{*/}
                                {/*            height: mobile ? "75px" : "90px",*/}
                                {/*            width: mobile ? "75px" : "90px",*/}
                                {/*            position: "absolute",*/}
                                {/*            top: mobile ? "-8px" : "0px",*/}
                                {/*            left: mobile ? "150px" : "180px"*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Link>*/}
                                <nav className="hidden xl:flex justify-center items-center pt-5">
                                    <ul className="px-2 list-reset py-2 xl:flex xl:justify-end">
                                        {homepageItems.map(item => {
                                            if (item.text === "Your venues") {
                                                if (loggedIn && isVenueAdmin)
                                                    return (
                                                        <MenuItem
                                                            key={item.text}
                                                            item={item}
                                                            linkClass={linkClass}
                                                            subClass={submenuClass}
                                                        />
                                                    );
                                                return null;
                                            }
                                            if (item.text === "Offers" && isAgency && isAgencyApproved && !isInternal) {
                                                item.path = "/commission-offers";
                                                item.text = "Agency Offers";
                                                if (typeof item.submenu !== "undefined" && typeof item.submenu[0] !== "undefined") {
                                                    item.submenu[0].path = "/special-offers";
                                                    item.submenu[0].text = "Special Offers";
                                                }
                                                return (
                                                    <MenuItem
                                                        key={item.text}
                                                        item={item}
                                                        linkClass={linkClass}
                                                        subClass={submenuClass}
                                                    />
                                                );
                                            } else if (item.text === "Offers" && !isAgency) {
                                                delete item.submenu;
                                                return (
                                                    <MenuItem
                                                        key={item.text}
                                                        item={item}
                                                        linkClass={linkClass}
                                                        subClass={submenuClass}
                                                    />
                                                );
                                            }
                                            if (item.text === "Venue news" && isAgency) return null;

                                            if (item.text === "Agency Info" && !isAgency) {
                                                return null;
                                            }
                                            if (item.text === "Site visits" && !isInternal) {
                                                return null;
                                            }
                                            if (item.text === "Agency Info" && isAgency && isAgencyApproved && !isInternal) {
                                                return (
                                                    <HashedMenuItem
                                                        key={item.text}
                                                        item={item}
                                                        linkClass={linkClass}
                                                        subClass={submenuClass}
                                                        ref={agencyOffersRef}
                                                    />
                                                );
                                            }

                                            return (
                                                <MenuItem
                                                    key={item.text}
                                                    item={item}
                                                    linkClass={linkClass}
                                                    subClass={submenuClass}
                                                />
                                            );
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </header>
                    </div>
                </>
            )}
        </HeaderWrap>
    );
};

Header.propTypes = {
    param_user: shape({
        name: string.isRequired,
        notifications: number.isRequired,
    }),
    loggedIn: bool.isRequired,
    isVenueAdmin: bool.isRequired,
};

export default withRouter(
    connect(state => ({
        loggedIn: isLoggedIn(state),
        isVenueAdmin: isVenueAdmin(state),
        isAgencyApproved: isAgencyApproved(state),
        isSuperAgent: isSuperAgent(state),
        isInternal: isInternal(state),
        brand: state.brand,
    }), {generalInteraction})(Header),
);
