import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { arrayOf, bool, number, shape, string } from "prop-types";
import axios from "axios";
import { ButtonBrand } from "Components/Button";
import { isLoggedIn, token, updateNotes, venueNotes } from "modules/auth";
import { venueInteraction } from "modules/analytics";
import PricingPackageModal from "./PricingPackageModal";
import { stateToReferCode } from "../../modules/brand";
import { user } from "../../modules/auth";
import { createHandleAxiosError } from "../../modules/api-tools";
import { pushEvent } from "../../modules/google-tag-manager";
import RequestForQuote from "Components/RequestForQuote";
import { VENUE_TAGS } from "../../modules/analytics/tags";
import PriceBandingBanner from "../../Components/PriceBanding/PriceBandingBanner";
import ExclusionDatesBanner from "../../Components/PriceBanding/ExclusionDatesBanner";

const VenuePriceBanding = (props) => {
  return <React.Fragment>
    <div
      className="bg-light-grey py-5 px-4 flex flex-wrap border-white border-b-2 pointer"
      onClick={props.onClick}
    >
      <div className="w-full">
        <h2 className="text-xl xl:text-base xxl:text-xl">
          {props.packageName}
        </h2>

        <span className="text-xs font-semibold text-green uppercase pointer">
          What does this include?
        </span>
      </div>

      <div className="w-full flex flex-col justify-center py-5">
        <span className="font-normal text-xs">FROM</span>
        <h1 className="text-edge-blue text-4xl xl:text-3xl xxl:text-4xl">
          <div>
            {props.priceBanding && !props.venuePriceBanding.is_international ?
              <React.Fragment>
                <span dangerouslySetInnerHTML={{ __html: props.html }} />
                {props.priceBanding && (
                  <span>{props.priceBanding.min_price}</span>)} {props.priceBanding && props.priceBanding.max_price ?
                <span>
                  <span>-</span>
                  <span dangerouslySetInnerHTML={{ __html: props.html }} />
                  <span>{props.priceBanding.max_price}</span>
                </span> : <></>}
              </React.Fragment> : null}
            {!props.priceBanding || props.venuePriceBanding.is_international ? <span>POA</span> : <></>}
          </div>
        </h1>
        <PriceBandingBanner type={props.type} vPB={props.venuePriceBanding} />
      </div>
      <br />
      {props.priceBanding && typeof props.venuePriceBanding[props.type + "_exclusion_text"] !== "undefined" && props.venuePriceBanding[props.type + "_exclusion_text"] ?
        <div className={"w-full"}>
          Excludes: <p className={"whitespace-pre-line break-words"}>{props.venuePriceBanding[props.type + "_exclusion_text"]}</p>
        </div>
        : null}
    </div>
    {props.modal === props.item.package_name ? (
      <PricingPackageModal
        closeModal={props.closeModal}
        enquire={props.enquire}
        venue={props.venue}
        venueSlug={props.venueSlug}
        title={props.packageName}
        package={props.item}
        priceBanding={props.priceBanding}
        venuePriceBranding={props.venuePriceBanding}
        packageType={props.type}
        currency={props.html}
        priceBandingExclusionText={props.priceBandingExclusionText}
      />
    ) : null}
  </React.Fragment>;
};

function InstantBooking(props) {
  return <div
    className="bg-white pt-6 pb-2 shadow text-center mb-6 md:mb-0 lg:mb-6"
    id="pricing"
  >
    <h4 className="text-black text-center text-xl mb-2">
      Instant Room Hire
    </h4>
    <p className="text-center text-xs xl:text-xs mb-3 font-normal">
      (excluding VAT &amp; subject to availability)
    </p>
    <div className="px-5">
      <p className="font-bold mb-1">Room hire for up to 10</p>
      <h1 className="font-heading font-bold mb-0 text-5xl text-edge-blue mb-2">
        <span dangerouslySetInnerHTML={{ __html: props.html }} />
        {parseFloat(props.string)
          .toFixed(2)
          .replace(".00", "")}
      </h1>
      <ButtonBrand
        id="buy-it-now"
        classes="mb-5 w-full text-base"
        onClick={props.onClick}
      >
        Instant booking
      </ButtonBrand>
    </div>
  </div>;
}

class Pricing extends React.Component {
  state = {
    modal: false,
    note: this.props.note ? this.props.note.content : "",
    noteUpdated: false,
    notesRows: 2,
  };

  static propTypes = {
    currency: string,
    meetingRoomFor10: string,
    venueId: number.isRequired,
    packages: arrayOf(
      shape({
        package_name: string.isRequired,
        price_per_delegate_inc_vat: string.isRequired,
      }),
    ),
    showNotes: bool,
  };

  static defaultProps = {
    curreny: "&pound;",
    showNotes: true,
  };

  componentDidMount() {
    if (this.props.location.search === "?buy-it-now") {
      this.props.venueInteraction({
        type: VENUE_TAGS.WEB_ENQUIRIES.BUY_IT_NOW,
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });
      this.setState({
        modal: "request-for-quote",
        quoteType: "Buy it now",
      });
    }
  }

  componentDidUpdate(prev) {
    const old = prev.note && prev.note.content;
    const newNote = this.props.note && this.props.note.content;
    if (old !== newNote) {
      this.setState({
        noteUpdated: true,
      });
    }
  }

  openModal = type => {
    this.setState({
      modal: type,
    });
  };

  closeModal = () => {
    this.setState({
      modal: "none",
    });
  };

  shareLink = () => {
    const { referCode } = this.props;

    const url = window.location.href;

    const urlQuery = url + (url.includes("?") ? "&" : "?");

    return encodeURIComponent(
      referCode ? `${urlQuery}referrer=${referCode}` : url,
    );
  };

  saveNote = () => {
    const { history, location } = this.props;
    const handleAxiosError = createHandleAxiosError({ history, location });

    axios[this.props.note ? "patch" : "post"](
      `${window.API}/users/me/notes/${this.props.match.params.venue}`,
      {
        content: this.state.note,
      },
      {
        headers: { Authorization: `Bearer ${this.props.token}` },
      },
    )
      .then(response => {
        this.props.updateNotes(response.data);
      })
      .catch(
        handleAxiosError(() => {
          alert("Sorry there was an error saving your note");
        }),
      );
  };

  shareEmailBody({ venueName }) {
    const { referCode, user, brand } = this.props;

    const sharedBy =
      user && user.first_name ? ` by ${user.first_name} ${user.surname}` : "";

    const href = window.location.href;

    const url =
      href +
      (referCode
        ? (href.includes("?") ? "&" : "?") + `referrer=${referCode}`
        : "");

    const from =
      brand && brand.company_name
        ? `${brand.company_name}, powered by EDGE Venues`
        : "EDGE Venues";

    const content = `Venue ${venueName} has been shared with you${sharedBy}.

  ${url}

${from}`;

    return encodeURIComponent(content);
  }

  handleVenueShare = event => {
    this.props.venueInteraction({
      type: VENUE_TAGS.VENUE_SHARED.SHARE_INDIVIDUAL_VENUE,
      id: this.props.venueId,
      impression_id: this.props.impression_id,
      data_score: this.props.data_score,
    });
    if (!this.props.isLoggedIn) {
      event.preventDefault();

      this.props.history.push(
        `/login?return=${encodeURI(
          this.props.location.pathname,
        )}&logInToProceed=1`,
      );
    }
  };

  render() {
    const { meetingRoomFor10, packages, currency } = this.props;
    if (!meetingRoomFor10 && (!packages || !packages.length)) {
      return null;
    }
    const hasMeetingRoomFor10 = !!parseFloat(meetingRoomFor10);
    const hasPackages = Array.isArray(packages) && packages.length === 2;
    const has24HrRate =
      hasPackages && !!parseFloat(packages[0].price_per_delegate_ex_vat);
    const hasDayRate =
      hasPackages && !!parseFloat(packages[1].price_per_delegate_ex_vat);

    const instantBookingOnClickEvent = () => {
      if (!this.props.isLoggedIn) {
        this.props.history.push(
          `/login?return=${encodeURI(
            this.props.location.pathname,
          )}&logInToProceed=1`,
        );
        return;
      }
      this.props.venueInteraction({
        type: VENUE_TAGS.WEB_ENQUIRIES.BUY_IT_NOW,
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });
      this.setState({
        modal: "request-for-quote",
        quoteType: "Buy it now",
      });
      pushEvent("enquire_venue_buynowclick", {
        ecommerce: {
          add: {
            products: [
              {
                id: this.props.venueId,
                name: this.props.venueName,
                location: this.props.location,
              },
            ],
          },
        },
      });
    };

    const bookNowOnClickEvent = () => {
      if (!this.props.isLoggedIn) {
        this.props.history.push(
          `/login?return=${encodeURI(
            this.props.location.pathname,
          )}&logInToProceed=1`,
        );
        return;
      }
      this.props.venueInteraction({
        type: VENUE_TAGS.WEB_ENQUIRIES.ENQUIRE_SIDEBAR,
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });

      pushEvent("enquire_venue_sidebar", {
        ecommerce: {
          add: {
            products: [
              {
                id: this.props.venueId,
                name: this.props.venueName,
                location: this.props.location,
              },
            ],
          },
        },
      });

      this.props.history.push(
        `/request-for-proposal?venues=${this.props.venueId}`,
      );
    };

    return (
      <>
        {/* Hiding Instant booking: https://edgevenues.atlassian.net/browse/EP-142 */}
        {false && hasMeetingRoomFor10 && (
          <InstantBooking
            html={currency}
            string={meetingRoomFor10}
            onClick={() => instantBookingOnClickEvent()}
          />
        )}

        {(has24HrRate || hasDayRate || this.props.venuePriceBanding) && (
          <div className="bg-white py-6 shadow mb-6 md:mb-0 lg:mb-6">
            <h4 className="text-black text-center text-xl mb-2">
              Conferences and Events
            </h4>
            <p className="text-center text-xs xl:text-xs mb-3 font-normal">
              (excluding VAT, guideline price prior to requirements)
            </p>
            {packages.map((item) => {
                let packageType = null;
                let packageName = item.package_name;
                let priceBanding = null;
                let priceBandingExclusionText = null;
                if (this.props.venuePriceBanding) {
                  if (item && item.package_name !== "24 Hour Delegate Rate" && item.package_name !== "Day Delegate Rate") {
                    return;
                  }
                  packageType = !!~item.package_name.indexOf("24") ? (this.props.venuePriceBanding.show_24hr_on_profile ? "twenty_four_hr" : "bb") : "ddr";
                  packageName = !!~item.package_name.indexOf("24") ? (this.props.venuePriceBanding.show_24hr_on_profile ? "24 Hour Delegate Rate" : "Bed & Breakfast") : "Day Delegate Rate";
                  priceBanding = this.props.venuePriceBanding[packageType + "_price_banding_rate"];
                  priceBandingExclusionText = this.props.venuePriceBanding[packageType + "_exclusion_text"];
                }

                const priceBandingOnClickEvent = () => {
                  this.props.venueInteraction({
                    type: packageType,
                    id: this.props.venueId,
                    impression_id: this.props.impression_id,
                    data_score: this.props.data_score,
                  });
                  if (packageType !== "bb") {
                    this.openModal(item.package_name);
                  }
                };

                return (
                  !!parseFloat(item.price_per_delegate_ex_vat) || this.props.venuePriceBanding ? (
                    <VenuePriceBanding
                      key={item.package_name}
                      onClick={() => priceBandingOnClickEvent()}
                      packageName={packageName} priceBanding={priceBanding}
                      venuePriceBanding={this.props.venuePriceBanding}
                      html={currency}
                      type={packageType}
                      modal={this.state.modal}
                      item={item}
                      closeModal={this.closeModal}
                      enquire={() => {
                        this.props.history.push(
                          `/request-for-proposal?venues=${this.props.venueId}`,
                        );
                      }}
                      venue={this.props.venueName}
                      venueSlug={this.props.venueSlug}
                      priceBandingExclusionText={priceBandingExclusionText}
                    />
                  ) : null
                );
              },
            )}
            <div className="px-5 mt-4">
              <ButtonBrand
                classes="w-full mb-2"
                onClick={() => bookNowOnClickEvent()}
              >
                Book Now
              </ButtonBrand>

              <div className={"flex justify-center"}>
                <ExclusionDatesBanner venue={this.props.venueSlug} />
              </div>
              {/*<ButtonBrand*/}
              {/*  outlined*/}
              {/*  Element="a"*/}
              {/*  classes="rounded-sm block w-full text-center font-normal border-4 justify-center px-2"*/}
              {/*  href={`mailto:?subject=${*/}
              {/*    this.props.venueName*/}
              {/*  }&body=${this.shareEmailBody({*/}
              {/*    venueName: this.props.venueName,*/}
              {/*  })}`}*/}
              {/*  onClick={this.handleVenueShare}*/}
              {/*  style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}*/}
              {/*>*/}
              {/*  <FaRegEnvelope className="mr-3" />*/}
              {/*  Share this on email*/}
              {/*</ButtonBrand>*/}
            </div>
          </div>
        )}
        {this.state.modal === "request-for-quote" ? (
          <RequestForQuote
            closeModal={this.closeModal}
            impression_id={this.props.impression_id}
            data_score={this.props.data_score}
            initialValues={{
              type: this.state.quoteType,
              venues: [this.props.venueId],
              venues_text: [this.props.venueName],
            }}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      isLoggedIn: isLoggedIn(state),
      token: token(state),
      note: venueNotes(props.venueId)(state)[0],
      referCode: stateToReferCode(state),
      brand: state.brand,
      user: user(state),
    }),
    { venueInteraction, updateNotes },
  )(Pricing),
);
