import React, { Component, lazy } from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import Modal from "Components/Modal";
import ImageGallery from "react-image-gallery";

import { color } from "../../modules/destinations";

const AverageTemperature = lazy(() =>
  import("./AverageTemperature" /* webpackChunkName: "average-temperature" */)
);

const TitleBar = ({ destination }) => (
  <div
    className="flex text-white font-bold text-xl"
    style={{
      backgroundColor: color,
    }}
  >
    <div className="py-2 px-4 flex flex-grow">
      <div className="flex-grow">Destination Profile</div>
      <div>
        {destination.city} - {destination.country.name}
      </div>
    </div>
    {destination.country.featured_image ? (
      <div
        style={{
          width: 70,
          background: `url("${destination.country.featured_image.url.replace('stage-api','api').replace('localapi','api')}") no-repeat center center / cover`,
        }}
      />
    ) : null}
  </div>
);

const Icon = ({ glyph }) => <i className={`fas fa-${glyph} mx-1`} />;

const continentIcon = {
  "North America": "globe-americas",
  "South America": "globe-americas",
  Europe: "globe-africa",
  Africa: "globe-africa",
  Asia: "globe-asia",
  Australia: "globe-asia",
};

const formatTimeDifference = diff => {
  const diffNumber = parseFloat(diff);
  if (isNaN(diffNumber)) return `${diff} Hours`;

  const isPlural = Math.abs(diffNumber) !== 1;
  const isPlus = diffNumber > 0;

  return `GMT ${isPlus ? "+" : ""}${diffNumber} ${isPlural ? "Hours" : "Hour"}`;
};

const InfoIcons = ({
  destination: { time_difference, flight_time, country },
}) => (
  <div className="flex flex-wrap py-4 border-black border-b justify-around">
    {time_difference ? (
      <div className="mr-4 my-2">
        <Icon glyph="clock" /> {formatTimeDifference(time_difference)}
      </div>
    ) : null}

    {country.currency_description ? (
      <div className="mr-4 my-2">
        <Icon glyph="money-bill-alt" /> {country.currency_description}
      </div>
    ) : null}

    <div className="mr-4 m-2">
      <Icon glyph={continentIcon[country.continent.name] || "globe-africa"} />{" "}
      Visa: {country.visa_required ? "Yes" : "No"}
    </div>

    {flight_time ? (
      <div className="mr-4 my-2" title="Based on flights from London">
        <Icon glyph="plane" /> From UK: {flight_time}
      </div>
    ) : null}
  </div>
);

class Gallery extends Component {
  state = {
    isOpen: false,
    startIndex: 0,
  };

  openImage = selectedImage => {
    this.setState({
      isOpen: true,
      startIndex: this.orderedImages().findIndex(
        image => image === selectedImage
      ),
    });
  };

  closeGallery = () => {
    this.setState({ isOpen: false });
  };

  // Put the featured image first
  orderedImages = () => {
    const {
      destination: { images, featured_image_id },
    } = this.props;

    if (!featured_image_id) return images;

    const featuredImage = images.find(image => image.id === featured_image_id);

    if (!featuredImage) return images;

    return [featuredImage, ...images.filter(image => image !== featuredImage)];
  };

  render() {
    const { isOpen, startIndex } = this.state;
    const images = this.orderedImages();

    return (
      <div className="my-2 -mx-2 flex justify-center">
        {isOpen ? (
          <Modal>
            <div className="modal" onClick={this.closeGallery}>
              <div
                className="center absolute lg:w-3/4"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <i
                  className="fal fa-times absolute z-50 pin-t pin-r text-white mr-2 mt-2 text-2xl pointer"
                  onClick={this.closeGallery}
                />
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={startIndex}
                  items={images.map(image => ({
                    original: image.url.replace('stage-api','api').replace('localapi','api') + "?raw=1",
                    thumbnail: image.url.replace('stage-api','api').replace('localapi','api') + "?raw=1",
                    renderItem: () => (
                      <div className="re-16/9 relative max-w-full">
                        <img
                          src={`${image.url.replace('stage-api','api').replace('localapi','api')}?raw=1`}
                          alt="Gallery"
                          className="absolute-center"
                        />
                      </div>
                    ),
                  }))}
                />
              </div>
            </div>
          </Modal>
        ) : null}

        {images.slice(0, 3).map((image, i) => (
          <div
            key={image.url.replace('stage-api','api').replace('localapi','api')}
            className={
              "px-2 w-full md:w-1/2 lg:w-1/3 " +
              (i !== 0 ? `hidden ${i === 1 ? "md" : "lg"}:inline-block` : "")
            }
            onClick={() => this.openImage(image)}
          >
            <div
              className="cursor-pointer relative bg-grey re-16/9"
              style={{
                background: `rgb(229, 234, 241) url("${image.url.replace('stage-api','api').replace('localapi','api')}") center center / cover`,
              }}
            >
              <div className="absolute pin-b pin-r text-xs m-1">
                <span className="fa-stack">
                  <i className="fa fa-circle fa-stack-2x icon-background" />
                  <i className="far fa-search-plus fa-stack-1x text-white" />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const MainText = ({
  destination: {
    profile,
    vfm,
    health_advice,
    country: { vfm: country_vfm, health_advice: country_health_advice },
  },
}) => {
  const hasHealthAdvice =
    (health_advice || country_health_advice || "none").toLowerCase() !== "none";

  return (
    <div className="my-2 text-sm ">
      {profile && (
        <p dangerouslySetInnerHTML={{ __html: profile.replace(/\r?\n/g, "<br />") }} />
      )}
      {/*{vfm || country_vfm ? (*/}
      {/*  <p>*/}
      {/*    <span className="font-bold">Value for money:</span>{" "}*/}
      {/*    {vfm || country_vfm}*/}
      {/*  </p>*/}
      {/*) : null}*/}
      {hasHealthAdvice ? (
        <>
          <span className="font-bold">Health Advice:</span>{" "}
          <p dangerouslySetInnerHTML={{ __html: (health_advice || country_health_advice)?.replace(/\r?\n/g, "<br />") }} />
        </>
      ) : null}
      {/* {notes ? (
        <p>
          <span className="font-bold">Notes:</span> {notes}
        </p>
      ) : null} */}
    </div>
  );
};

const CountryDestinations = ({
  destination: { similar_destinations, country },
}) => (
  <div className="p-4">
    <div>
      <p className="font-bold">Destinations within {country.name}</p>
      {similar_destinations.map(({ id, city }, i) => (
        <Link
          key={id}
          to={`/destination/${city}`}
          className={`block py-2 underline border-black border-b-2 text-black ${
            i === 0 ? "border-t-2" : ""
          } ${similar_destinations.length > 6 ? "text-sm" : ""}`}
        >
          {city}
        </Link>
      ))}
    </div>
  </div>
);

const Profile = ({ destination }) => (
  <div className="bg-white">
    <TitleBar destination={destination} />
    <div className="px-4 pb-4">
      <InfoIcons destination={destination} />
      <Gallery destination={destination} />
      <MainText destination={destination} />

      <div className="px-2 bg-grey flex flex-wrap justify-center">
        {destination.similar_destinations &&
        destination.similar_destinations.length ? (
          <div className="w-full md:w-2/5">
            <CountryDestinations destination={destination} />
          </div>
        ) : null}
        <div className="w-full md:w-3/5 self-center">
          <AverageTemperature destination={destination} />
        </div>
      </div>
    </div>
  </div>
);

Profile.propTypes = {
  destination: propTypes.object.isRequired,
};

export default Profile;
